@import "font/vsb-drive-font.css";

body {
  font-family: "Drive", "Verdana", sans-serif;
}

h1 {
  padding-bottom: 0.2em;
  margin-bottom: 1em;
  border-bottom: 1px solid gray;
}

h1 > .header-type {
  margin-left: 1em;
  font-size: 0.6em;
  font-weight: normal;
  color: #6c757d;
}

@include media-breakpoint-down(sm) {
  h1 > .header-type {
    display: block;
    margin-left: 0;
    margin-top: 0.3em;
  }
}

input[readonly] {
  color: #6c757d;
}

div.required label.form-label::after,
label.required::after {
  color: $red;
  content: " *";
}
