/*  Mapping of DRIVE font for VSB main pages */


/* thin */

@font-face {
	font-family: "Drive";
	font-style: normal;
	font-weight: 100;
	src: url('Drive-Thin.eot') format('embedded-opentype'), url('Drive-Thin.ttf') format('truetype');
}

@font-face {
	font-family: "Drive";
	font-style: italic;
	font-weight: 100;
	src: url('Drive-ThinItalic.eot') format('embedded-opentype'), url('Drive-ThinItalic.ttf') format('truetype');
}

@font-face {
	font-family: "Drive";
	font-style: normal;
	font-weight: 200;
	src: url('Drive-Thin.eot') format('embedded-opentype'), url('Drive-Thin.ttf') format('truetype');
}

@font-face {
	font-family: "Drive";
	font-style: italic;
	font-weight: 200;
	src: url('Drive-ThinItalic.eot') format('embedded-opentype'), url('Drive-ThinItalic.ttf') format('truetype');
}


/* normal */ 
    
@font-face {
	font-family: "Drive";
	font-style: normal;
	font-weight: 300;
	src: url('Drive-Book.eot') format('embedded-opentype'), url('Drive-Book.ttf') format('truetype');
}

@font-face {
	font-family: "Drive";
	font-style: normal;
	font-weight: 400;
	src: url('Drive-Book.eot') format('embedded-opentype'), url('Drive-Book.ttf') format('truetype');
}

@font-face {
	font-family: "Drive";
	font-style: italic;
	font-weight: 300;
	src: url('Drive-BookItalic.eot') format('embedded-opentype'), url('Drive-BookItalic.ttf') format('truetype');
}

@font-face {
	font-family: "Drive";
	font-style: italic;
	font-weight: 400;
	src: url('Drive-BookItalic.eot') format('embedded-opentype'), url('Drive-BookItalic.ttf') format('truetype');
}


/* medium */

@font-face {
	font-family: "Drive";
	font-style: normal;
	font-weight: 500;
	src: url('Drive-Medium.eot') format('embedded-opentype'), url('Drive-Medium.ttf') format('truetype');
}

@font-face {
	font-family: "Drive";
	font-style: italic;
	font-weight: 500;
	src: url('Drive-MediumItalic.eot') format('embedded-opentype'), url('Drive-MediumItalic.ttf') format('truetype');
}


/* bold */

@font-face {
	font-family: "Drive";
	font-style: normal;
	font-weight: 600;
	src: url('Drive-Bold.eot') format('embedded-opentype'), url('Drive-Bold.ttf') format('truetype');
}

@font-face {
	font-family: "Drive";
	font-style: normal;
	font-weight: 700;
	src: url('Drive-Bold.eot') format('embedded-opentype'), url('Drive-Bold.ttf') format('truetype');	
}

@font-face {
	font-family: "Drive";
	font-style: italic;
	font-weight: 600;
	src: url('Drive-BoldItalic.eot') format('embedded-opentype'), url('Drive-BoldItalic.ttf') format('truetype');
}


@font-face {
	font-family: "Drive";
	font-style: italic;
	font-weight: 700;
	src: url('Drive-BoldItalic.eot') format('embedded-opentype'), url('Drive-BoldItalic.ttf') format('truetype');
}


/* extrabold */

@font-face {
	font-family: "Drive";
	font-style: normal;
	font-weight: 800;
	src: url('Drive-Extrabold.eot') format('embedded-opentype'), url('Drive-Extrabold.ttf') format('truetype');
}

@font-face {
	font-family: "Drive";
	font-style: italic;
	font-weight: 800;
	src: url('Drive-ExtraboldItalic.eot') format('embedded-opentype'), url('Drive-ExtraboldItalic.ttf') format('truetype');
}

@font-face {
	font-family: "Drive";
	font-style: normal;
	font-weight: 900;
	src: url('Drive-Extrabold.eot') format('embedded-opentype'), url('Drive-Extrabold.ttf') format('truetype');
}

@font-face {
	font-family: "Drive";
	font-style: italic;
	font-weight: 900;
	src: url('Drive-ExtraboldItalic.eot') format('embedded-opentype'), url('Drive-ExtraboldItalic.ttf') format('truetype');
}